import React from "react";
import classNames from "classnames";
import { AppLink } from "@base";
import { Container, ButtonAlt, Button } from "@atoms";
import { CardShare, HeadingWIcon, Card } from "@molecules";
import { m } from "framer-motion";

const ActionThankYou = ({
  thankYouStepVariants,
  actionState,
  actionDispatch,
  issue,
  shareCta,
  shareUrlOverride,
  formid,
  url,
  completeAgainVariants,
  completedHeading,
  thankYouHeading,
  completedCopy,
  thankYouCopy,
  romero,
  upNext,
  lgca,
}) => {
  return (
    <m.div
      variants={thankYouStepVariants}
      animate={thankYouStepVariants[actionState.status]}
      className={classNames("w-1/2 overflow-hidden")}
    >
      {(actionState.status === "submitted" ||
        actionState.status === "completed") && (
        <Container>
          <div className="mb-4">
            {!issue && (
              <ButtonAlt to="/" reverse className="text-white">
                Go to Campaigns
              </ButtonAlt>
            )}
            {issue && issue.url && (
              <ButtonAlt to={issue.url} reverse className="text-white">
                Go to {issue.title} Campaign
              </ButtonAlt>
            )}
          </div>
          <CardShare
            status={actionState.status}
            heading={
              actionState.status === "completed"
                ? completedHeading
                : thankYouHeading
            }
            copy={
              actionState.status === "completed" ? completedCopy : thankYouCopy
            }
            shareCta={shareCta}
            shareUrlOverride={shareUrlOverride}
            formid={formid}
            trackingId={actionState.trackingId}
            play={
              actionState.status === "submitted" ||
              actionState.status === "completed"
            }
            url={url}
          />
          <m.div
            variants={completeAgainVariants}
            animate={completeAgainVariants[actionState.status]}
            className="hidden"
          >
            <div
              className={classNames(
                "flex w-full items-center justify-center text-center",
                {
                  "mt-12": upNext?.length > 0,
                  "my-12": upNext?.length === 0,
                }
              )}
            >
              <Button
                className="mx-auto"
                rounded={romero}
                color={romero ? "blue" : "red"}
                onClick={() =>
                  actionDispatch({ type: "setStatus", status: "reset" })
                }
              >
                Complete this action again
              </Button>
            </div>
          </m.div>
          {typeof window !== "undefined" && upNext?.length > 0 && (
            <div className="mt-20 w-full">
              <HeadingWIcon
                icon="turtle"
                heading="Up Next For You"
                headingColor="text-white"
                inverse={!lgca}
                small
              />
              <div className="-mx-6 my-12 flex flex-wrap">
                {upNext.map(action => {
                  return (
                    <div
                      className={`w-full sm:w-1/2 md:w-1/${upNext.length} mb-6 px-6`}
                    >
                      <AppLink to={action.url} className="group">
                        <Card
                          heading={action.heading}
                          image={action.metaImage}
                        />
                      </AppLink>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Container>
      )}
    </m.div>
  );
};

export default ActionThankYou;
